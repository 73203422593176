.statistics_table {
  border: 1px solid black;

th {
  border: 1px solid black;
}


td {
  border: 1px solid black;
}

}
.grid_container {
  position: relative;
  display: grid;
  width: 100%;
  height: 100%;
}

.border_header_left {
  text-align:center;
  border-bottom: 2px solid transparent;
  border-image: linear-gradient(90deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.47) 25%, #FFF 52.08%, rgba(255, 255, 255, 0.47) 75%, rgba(255, 255, 255, 0.47) 75.01%, rgba(255, 255, 255, 0.00) 95.31%);
  border-image-slice: 1;
}

.border_header_right {
  text-align:center;
  border-bottom: 2px solid transparent;
  border-image: linear-gradient(91deg, rgba(45, 93, 252, 0.48) 0%, rgba(45, 93, 252, 0.16) 0%, rgba(45, 93, 252, 0.60) 16.67%, rgba(45, 93, 252, 0.64) 33.85%, rgba(45, 93, 252, 0.80) 50%, rgba(45, 93, 252, 0.64) 65.63%, rgba(45, 93, 252, 0.60) 82.81%, rgba(45, 93, 252, 0.16) 98.96%);
  border-image-slice: 1;
}
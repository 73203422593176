.nx_grid_wrapper {
  padding: 12px 0 0 0;
  > div > div > div > div > div > div.MuiDataGrid-row.Mui-selected > div:focus, > div > div > div > div > div > div.MuiDataGrid-row.Mui-selected > div:active {
    outline: none;
  }

   > div.MuiDataGrid-main.css-204u17-MuiDataGrid-main > div > div > div > div > div > div:focus {
    outline: none;
  }
}


.q-chip {
  border-radius: 15px;
  background: rgba(45, 93, 252, 0.06);
  color: #2D5DFC;
  font-size: 14px;
  height: 2em;
  margin: 4px;
  max-width: 100%;
  outline: 0;
  padding: 0.5em 0.9em;
  position: relative;
  vertical-align: middle;
}

.loading_filters {
  position: relative;
  display: flex;
  padding: 10px 20px;
}

.nx_grid_filters {
  height: 40px!important;
  padding: 6px 36px 6px 10px!important;
  align-items: center!important;
  color: rgba(45, 93, 252, 0.80);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.nx_grid_popover {
  position: absolute!important;
  top: 600px;
  left: 600px;
  z-index: 11;
  width: 226px!important;
  height: 66px!important;
  border-radius: 7px!important;
  border: 1px solid rgba(45, 93, 252, 0.20);
  background: #F9F8F8!important;
  box-shadow: 4px 4px 8px 0px rgba(34, 42, 96, 0.12), -4px -4px 8px 0px rgba(34, 42, 96, 0.12);
  grid-template-columns: 85px auto;
}

.nx_grid_popover_text {
  color: rgba(251, 251, 252, 0.90) !important;
  text-align: center!important;
  font-family: Poppins!important;
  font-size: 12px!important;
  font-style: normal!important;
  font-weight: 500!important;
  line-height: normal!important;
  justify-content: center!important;
  align-items: center!important;
  background-color: rgba(45, 93, 252, 0.80) !important;
}


.loader {
  z-index: 100!important;
  position: absolute!important;
  width : 100% !important;
  height : 100% !important;
  background-color: transparent!important;
  justify-content: center!important;
  align-items: center!important;
  display: grid;
}
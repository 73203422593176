.menu_item {
  position: relative;
  display: block;
  width: 280px;
  height: 57px;
  color: #A5A7AF!important;
  font-size: 16px;
}

.menu_item_active {
  border-radius: 10px!important;
  background-color: rgba(40, 40, 40, 0.35) !important;
  color : white!important;
}

.css-1domaf0 {
  width : 280px!important;
}
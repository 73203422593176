.header-item {
  color: rgba(34, 42, 96, 0.70) !important;
  font-size: 14px !important;
  text-transform: capitalize !important;
  height: 35px !important;
  text-align: start !important;
  padding: 0;
  margin: 0 !important;
  border: 1px solid transparent !important;
}

.header-item:hover {
  border-radius: 5px !important;
  border: 1px solid rgba(45, 93, 252, 0.60) !important;
  color: rgba(45, 93, 252, 0.60) !important;
  background: rgba(45, 93, 252, 0.10) !important;
}

.header-item:active {
  border-radius: 5px !important;
  border: 1px solid rgba(45, 93, 252, 0.60) !important;
  color: rgba(45, 93, 252, 0.60) !important;
  background: rgba(45, 93, 252, 0.10) !important;
}


.header-menu > div > ul {
  border-radius: 5px;
  background: rgba(30, 30, 30, 0.5);
  box-shadow: 5px 5px 10px 0px rgba(34, 42, 96, 0.20), -5px -5px 10px 0px rgba(34, 42, 96, 0.07);
  padding: 0 !important;

  .header-menu-item {
    background-color: #5F6060;
    color: #A5A7AF;
    font-size: 16px;
    font-weight: 500;
    justify-self: center;
    //text-align: center !important;


    div {
      text-align: left;
      width: 100%;
    }

  }


  .header-menu-item-active {
    background-color: #4C4C4C;
    color : #FFFFFF;
    border-bottom: 2px solid #F8FAFB !important;
  }

  .header-menu-item:hover {
    background: rgba(45, 93, 252, 0.05);
    box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 3px, rgba(0, 0, 0, 0.1) 0px 2px 3px;
    z-index: 5;
  }
}

@media screen and (max-width: 1367px) {
  .header_container {
    width: 45%!important;
  }  
}



.print {
    width: 35%;
    height: 115%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    background-color: #ffffff;
    box-shadow: 5px 10px 10px 5px #888888;
    justify-content: space-between;
    transform: scale(0.5);
}

.print-first-text {
    margin: 0 0 0 0;
    justify-self: start;
    height: auto;
    width: 60%;
    background-color: white;
    text-align: center;
    transform: rotate(180deg);
}

.print-second-text {
    margin: 0 0 0 0;
    justify-self: end;
    height: auto;
    width: 60%;
    background-color: white;
    text-align: center;
}

.print-wrapper {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
}

.print-button {
    display: block;
}

@media print {
    /*header, footer, aside, form, button {*/
        /*display: none !important;*/
    /*}*/

    /*.print, .print-wrapper, .print-wrapper * {*/
        /*visibility: visible !important;*/
    /*}*/

    /*body * {*/
        /*visibility: hidden !important;*/
    /*}*/

    /*.print-button {*/
        /*display: none !important;*/
    /*}*/

    /*.print-text {*/
        /*display: none !important;*/
    /*}*/

    /*.print-wrapper {*/
        /*padding: 0;*/
        /*margin: 0;*/
        /*justify-content: center;*/
        /*align-content: center;*/
        /*box-sizing: border-box;*/
        /*display: flex;*/
        /*width: 100%;*/
        /*height: 100%;*/
        /*position: relative;*/
        /*background-color: red;*/
    /*}*/

    .print {
        width: 100%;
        height: 100%;
        transform: unset;
        box-shadow: unset;
    }
}